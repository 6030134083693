import React,{useState} from 'react'
import DisplayImages from './DisplayImages'
import "./AllEvents.css"
import monchlogo from "../images/icons/monch-logo.png"
import iftar2024logo from "../images/icons/iftar2024-logo.png"
import onam2023logo from "../images/icons/onam2023-logo.png"
import eidtour2024logo from "../images/icons/eidtour2024-logo.png"
import retfest2024logo from "../images/icons/retfest2024-logo.png"
import khalblogo from "../images/icons/khalb-logo.png"

const AllEvents = () => {
    const [flag, setFlag] = useState("");

    return (
        
    <div>
        
<div className='menu-div'>


<img 
        src={khalblogo}
        alt="Placeholder"
        onClick={() => setFlag("khalbanmanjeri")}
        width={120} height={120}
        style={{ cursor: 'pointer' }} // Makes the image clickable with a pointer cursor
        />
<img 
        src={monchlogo}
        alt="Placeholder"
        onClick={() => setFlag("monchmanjeri")}
        width={120} height={120}
        style={{ cursor: 'pointer' }} // Makes the image clickable with a pointer cursor
        />

<img 
        src={iftar2024logo}
        alt="Placeholder"
        onClick={() => setFlag("iftar2024")}
        width={120} height={120}
        style={{ cursor: 'pointer' }} // Makes the image clickable with a pointer cursor
        />

<img 
        src={onam2023logo}
        alt="Placeholder"
        onClick={() => setFlag("onam2023")}
        width={120} height={120}
        style={{ cursor: 'pointer' }} // Makes the image clickable with a pointer cursor
        />

<img 
        src={eidtour2024logo}
        alt="Placeholder"
        onClick={() => setFlag("eidtour2024")}
        width={120} height={120}
        style={{ cursor: 'pointer' }} // Makes the image clickable with a pointer cursor
        />

<img 
        src={retfest2024logo}
        alt="Placeholder"
        onClick={() => setFlag("retfest2024")}
        width={120} height={120}
        style={{ cursor: 'pointer' }} // Makes the image clickable with a pointer cursor
        />
</div>


{flag==="khalbanmanjeri" || flag==""?<DisplayImages folder="khalbanmanjeri" progtitle="മൊഞ്ചുള്ള മഞ്ചേരി 2019"/>:null}
{flag==="monchmanjeri"?<DisplayImages folder="monchmanjeri" progtitle="മൊഞ്ചുള്ള മഞ്ചേരി 2019"/>:null}
{flag==="iftar2024"?<DisplayImages folder="iftar2024" progtitle="ഇഫ്താർ മീറ്റ് 2024"/>:null}
{flag==="onam2023"?<DisplayImages folder="onam2023" progtitle="ഓണാഘോഷം 2023"/>:null}
{flag==="eidtour2024"?<DisplayImages folder="eidtour2024" progtitle="ഈദ് ടൂർ 2024"/>:null}
{flag==="retfest2024"?<DisplayImages folder="retfest2024" progtitle="റിയാദോർമകൾ 2024"/>:null}
 </div>
  )
}

export default AllEvents